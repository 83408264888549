import React from "react"

export default class LoginLink extends React.Component<any, any> {

    componentDidMount() {
        window.location.replace("https://kundeservice.vibb.no/oss-brikken-og-tibber-pulse");
    }

    render() {
        return (
            <div>
                Redirecting to <a href="https://kundeservice.vibb.no/oss-brikken-og-tibber-pulse" target="_blank" rel="noopener">https://kundeservice.vibb.no/oss-brikken-og-tibber-pulse</a>
            </div>
        )
    }
}
